import axios from 'axios';

// Base URL for API calls
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// API service for managing SKU mappings, country rules, and NSS products
const api = {
  getSkuMappings: async () => {
    return axios.get(`${API_BASE_URL}/skus`);
  },
  deleteSkuMapping: async (mappingId) => {
    return axios.delete(`${API_BASE_URL}/skus/${mappingId}`);
  },
  getCountryRules: async () => {
    return axios.get(`${API_BASE_URL}/countries`);
  },
  getCountryProductRules: async () => {
    return axios.get(`${API_BASE_URL}/country-product-rules`);
  },
  getShopifyProducts: async () => {
    return axios.get(`${API_BASE_URL}/shopify-products`);
  },
  getNssProducts: async () => {
    return axios.get(`${API_BASE_URL}/nss-products`);
  },
  updateSkuMapping: async (skuMapping) => {
    return axios.post(`${API_BASE_URL}/skus`, skuMapping);
  },
  updateCountryRules: async (countryRules) => {
    return axios.post(`${API_BASE_URL}/countries`, countryRules);
  },
  updateNssProducts: async (nssProduct) => {
    return axios.post(`${API_BASE_URL}/nss-products`, nssProduct);
  },
  syncProductsWithNSS: async () => {
    return axios.post(`${API_BASE_URL}/nss-products/sync`);
  },
  syncProductsWithShopify: async () => {
    return axios.post(`${API_BASE_URL}/shopify-products/sync`);
  },
  saveCountryProductRule: async (ruleData) => {
    return axios.post(`${API_BASE_URL}/country-product-rules`, ruleData);
  },
};

export default api;
