import React, { useState, useEffect } from 'react';
import countriesList from '../helpers/countries';
import Select from 'react-select';
import api from '../services/api';
import './CountryRules.scss';

const CountryRules = () => {
  const [countries, setCountries] = useState([]);
  const [countryRules, setCountryRules] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [weight, setWeight] = useState('');
  const [declaredValue, setDeclaredValue] = useState('');

  useEffect(() => {
    async function fetchCountryRules() {
      const result = await api.getCountryRules();
      setCountryRules(result.data);
    }
    fetchCountryRules();

    setCountries(
      countriesList.map(({ code, name }) => ({
        value: code,
        label: name,
      })),
    );
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const countryRule = {
      country_code: selectedCountry.value,
      country_name: selectedCountry.label,
      weight_limit: weight,
      declared_value_limit: declaredValue,
    };
    await api.updateCountryRules(countryRule);
    alert('Country Rule updated!');
  };

  return (
    <div className="country-rules">
      <h2>Country Rules</h2>
      <form onSubmit={handleSubmit} className="country-form">
        <label>Country Code:</label>
        <Select
          options={countries}
          onChange={setSelectedCountry}
          value={selectedCountry}
        />
        <label>Weight Limit:</label>
        <input
          type="number"
          name="weight_limit"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
        <label>Declared Value Limit:</label>
        <input
          type="number"
          name="declared_value_limit"
          value={declaredValue}
          onChange={(e) => setDeclaredValue(e.target.value)}
        />
        <button type="submit">Add/Update Country Rule</button>
      </form>

      <h3>Current Country Rules</h3>
      <ul className="rules-list">
        {countryRules.map((rule) => (
          <li key={rule.country_code} className="rule-item">
            {rule.country_name}: {rule.weight_limit}g, $
            {rule.declared_value_limit}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CountryRules;
